import React from "react";
import NumberDisplay from "./fields/NumberDisplay";
class PreviewLineItem extends React.Component {

  render() {

    const { line, currency } = this.props;

    // Remove any price values for product line items and any other properties that are not displayed elsewhere
    const additionalProperties = Object.keys(line).filter((p, index) => {
      if (p.startsWith("hs_price") && "hs_product_id" in line) {
        return false;
      }
      // hide properties that are displayed elsewhere
      return ![
        "name",
        "hs_sku",
        "price",
        "quantity",
        "linepilot_id",
        "hs_position_on_quote",
        "createdate",
        "hs_lastmodifieddate",
        "hs_object_id",
        "hs_product_id",
      ].includes(p);
    });
    return (
      <div className="flex flex-row w-full py-2 border-b border-gray-200 border-dashed">
        <div className="flex flex-row flex-1">
          <p className="w-24 text-sm text-gray-400">{line.hs_sku || ""}</p>
          <div className="text-sm font-medium text-gray-900">
            <div>
              {line?.hs_product_id ? (
                <a
                  href={`https://${this.props.hubspot.domain}/contacts/${this.props.hubspot.hub_portal}/objects/0-7/views/all/list`}
                  className="underline"
                >
                  {line.name}
                </a>
              ) : (
                <>{line.name}</>
              )}
              <span className="text-xs text-gray-400"> x {line.quantity || 0}</span>
            </div>
            {!line?.linepilot_id && (
              <div className="text-xs text-gray-400">Created outside LinePilot</div>
            )}
            {line?.linepilot_id && !line?.thisTemplate && (
              <div className="text-xs text-gray-400">Created from another LinePilot template</div>
            )}
            {additionalProperties.length > 0 && (
              <table>
                <tbody>
                  {additionalProperties.map((p, i) => {
                    const propertyDefinition = this.props.lineItemPropertyDefinitions.find(
                      (i) => i.name === p
                    );
                    let value;
                    if (propertyDefinition?.options.length > 0) {
                      value = propertyDefinition?.options.find((o) => o.value === line[p])?.label;
                    } else if (propertyDefinition?.type === "date") {
                      value = new Date(line[p]).toLocaleDateString();
                    } else if (propertyDefinition?.showCurrencySymbol) {
                      value = <NumberDisplay value={line[p]} currency={currency} />;
                    } else {
                      value = line[p];
                    }
                    return (
                      <tr key={`additionalProperties_${i}`}>
                        <td className="text-xs text-gray-400">{propertyDefinition?.label}</td>
                        <td className="pl-2 text-xs ">{value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="flex-shrink min-w-36">
          <div className="text-sm text-gray-500">
            <NumberDisplay value={line.price} currency={currency} />
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewLineItem;
